import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import bannerStyle from './banner.module.css'

const Banner = () => (
    <section className={bannerStyle.BannerSection}>


    </section>
)

export default Banner